import postMessageToMobileApp from '~/lib/postMessageToMobileApp.js'

export const ACTIONS = {
  BOOKING: 'BookingRequest',
  ENQUIRY: 'Enquiry',
}

type MessageAppToSignup = {
  successUrl: string
  failureUrl: string
  cookies: Record<string, string | null | undefined>
  ownerName?: null | string
  action?: string
}

export const messageAppToSignup = (
  { successUrl, failureUrl, cookies, ownerName = null, action = ACTIONS.BOOKING }: MessageAppToSignup,
  messenger = postMessageToMobileApp,
) => {
  const extraJson = { name: ownerName, callToAction: action }
  const payload = {
    android: { func: 'signup', params: [successUrl, failureUrl, cookies, JSON.stringify(extraJson)] },
    ios: {
      command: 'signup',
      parameters: {
        successURL: successUrl,
        failureURL: failureUrl,
        cookiesJSON: JSON.stringify(cookies),
        extraJson,
      },
    },
  }
  return messenger(payload)
}
